import auditService from "../../services"

export default {
    data() {
        return {
            searchedResultShow: false,
            logs: [],
            hasSuperAdmin: false,
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            search: "",
            meta:{}
        };
    },
    watch: {
        'country_id': function () {
            this.$router.replace({query: { country: this.country_id }});
            this.getRecord();
        }
    },
    mounted() {
        if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.getRecord();
        this.getNextRecord();
    },
    methods: {
        isLink(e) {
            return e && typeof e == "object";
        },
        getRecord() {
            this.showLoader(true);
            auditService.getLogs({
                "country_id": this.country_id,
                "search": this.search,
            }).then(response => {
                this.hideLoader();
                this.logs = this.setAuditLogLinks(response.data.data);
                this.meta = response.data.meta;
            });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    auditService.getLogs({
                        "country_id": this.country_id,
                        "search": this.search,
                        "page": this.meta.current_page+1
                    }).then(response => {
                        this.hideLoader();
                        this.logs.push(...this.setAuditLogLinks(response.data.data));
                        this.meta = response.data.meta;
                        this.loading = false;
                    })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getRecord();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getRecord();
            this.searchedResultShow = false;
        }
    }
}