<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Audit Logs</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>
<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div v-for="record, index in logs" class="mt-2">
        <div class="card card-style mb-0">
            <div class="content mb-2 mt-2">
                <p class="font-14 mb-1 font-500 letterSpacing">
                    <span v-for="(part, partKey) in record.message_link">
                        <template v-if="isLink(part)">
                            <router-link :to="{'name': part.module, params: {'id' : part.id}}" class="font-800">{{
                                part.text }}
                            </router-link>
                        </template>
                        <template v-else>{{ part }}</template>
                    </span>
                </p>
                <div class="d-flex justify-content-between align-items-center">
                    <p class="font-13 badge primary-alt-light-bg rounded-pill mt-1 mb-1 text-capitalize letterSpacing"
                        v-if="record.group == 'ticket'">
                        {{ record.group }}
                    </p>
                    <p class="font-13 badge primary-light-bg rounded-pill mt-1 mb-1 text-capitalize letterSpacing"
                        v-if="record.group == 'wallet'">
                        {{ record.group }}
                    </p>
                    <p class="font-13 badge bg-secondary rounded-pill mt-1 mb-1 text-capitalize letterSpacing"
                        v-if="record.group == 'account'">
                        {{ record.group }}
                    </p>
                    <p class="font-13 badge primary-alt-base-bg rounded-pill mt-1 mb-1 text-capitalize letterSpacing"
                        v-if="record.group == 'commission'">
                        {{ record.group }}
                    </p>
                    <p class="font-13 badge primary-light-bg rounded-pill mt-1 mb-1 text-capitalize letterSpacing"
                        v-if="record.group == 'margin'">
                        {{ record.group }}
                    </p>
                    <span class="font-12">{{ localDateTime(record.created_at) }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(logs.length>0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Audit Logs Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search "
                v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>