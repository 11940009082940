import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }

    getLogs(data) {
        return api.instance.get("/audit-logs", {
            params: data
        });
    }
};